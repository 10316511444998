import { Container, Row, Col, Card } from "react-bootstrap";
import DocumentComponent from "../components/Document.component";
import HeroComponent from "../components/Hero.component";
import i18n from "../i18n"
import { formatter } from "../utils/Toolbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBottleWater, faDollar, faRuler, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import modelsData from "../data/models.json"
import PremiumUpgradesComponent from "../components/PremiumUpgrades.component";
import ContainerDivider from "../components/ContainerDivider.component";
import ScheduleComponent from "../components/Schedule.component";
import { getUrlLangPrefix } from "../utils/Local";


export interface IModel {
    name: string,
    url_name: string,
    size: string,
    price: number,
    bottles: number,
    description: string,
    slug: string,
    hero: string,
    img: string,
    footprint: number,
    total_living_space: number,
    sizes?: Array<{label: string, value: number}>

}

export default function Models() {

    const { t } = i18n;
    const langPrefix = getUrlLangPrefix()

    /** Fetch first variant of each product */
    const models = [
        modelsData[0]['variants'][0] as IModel,
        modelsData[1]['variants'][0] as IModel,
        modelsData[2]['variants'][0] as IModel,
        modelsData[3]['variants'][0] as IModel,
        modelsData[4]['variants'][0] as IModel,
        modelsData[5]['variants'][0] as IModel,
    ]

    return (<>

        <DocumentComponent title={t('signature_models')} />

        <HeroComponent title={t('signature_models')} />

        <Container style={{ marginBottom: '5rem' }}>
            <Row>
                {
                    models.map((model: IModel, index: number) => {

                        return (<Col key={index} lg={6} className="mb-5 mb-lg-4">

                            <Card className="p-0 h-100">

                                <NavLink to={`${langPrefix}/${t('_route.models')}/${model.url_name}`} className='text-center text-lg-end'>
                                    <Card.Img variant="top" src={model.hero} />
                                </NavLink>

                                <Card.Body className="modelSummaryContainer">
                                    <Card.Title><h2 className="modelHeader text-center">{t(model.name.split(' ')[0])}</h2></Card.Title>

                                    <Card.Text className="modelSubHeader mb-0">
                                        <FontAwesomeIcon icon={faBottleWater} color="var(--info-color)" />
                                        <span>{t('made_from_x_recycled_water_bottles', { x: model.bottles.toLocaleString() })}</span>
                                    </Card.Text>
                                    <Card.Text className="modelSubHeader mb-0">
                                        <FontAwesomeIcon icon={faDollar} color="var(--info-color)" />
                                        <span>{t('starting_from_price', { price: formatter.format(model.price) })}</span>
                                    </Card.Text>
                                    <Card.Text className="modelSubHeader mb-0">
                                        {model.sizes ?
                                        <span className="d-flex flex-column">
                                            {model.sizes.map((modelSize, modelSizeIndex) => {
                                                return (
                                                    <span className="d-flex" key={modelSizeIndex}>
                                                    <FontAwesomeIcon icon={faRuler} color="var(--info-color)" />
                                                    {t(modelSize.label)}: {modelSize.value} {t('sq_ft')}
                                                    </span>
                                                )
                                            })}
                                        </span>
                                        :
                                        <>
                                            <FontAwesomeIcon icon={faRuler} color="var(--info-color)" />
                                            <span>{t('size')}: {model.footprint} {t('sq_ft')}</span>
                                        </>
                                        }
                                       
                                    </Card.Text>

                                    <Card.Text className="mt-3">
                                        {t(model.description)}
                                    </Card.Text>
                                </Card.Body>

                                <Card.Footer style={{ background: 'unset', border: 'unset' }} className=" mb-2">
                                    <NavLink to={`${langPrefix}/${t('_route.models')}/${model.url_name}`} className='text-center text-lg-end'>
                                        <span style={{ fontWeight: 600, fontSize: '1.1rem', color: 'var(--info-color)' }}>{t('learn_more')} <FontAwesomeIcon icon={faChevronRight} /></span>
                                    </NavLink>
                                </Card.Footer>
                            </Card>

                        </Col>
                        )
                    })
                }
            </Row>

        </Container >

        <ContainerDivider title={t('premium_upgrades')} />

        <PremiumUpgradesComponent />

        <ScheduleComponent />

    </>)
}