import i18n from "../../i18n";
import ExhibitionComponent from "../Exhibition.component";
import {IExhibition} from "../../interfaces/IExhibition";

const ExhibitionScreen: React.FC = () => {
    const { t } = i18n;
    const list = [
        {
            address: t('_exhibitions.1.address'),
            date: t('_exhibitions.1.date'),
            btnLabel: t('_exhibitions.1.btn_label'),
            link: t('_exhibitions.1.link'),
            googleMap: 'https://maps.app.goo.gl/hn2nQSZorRnU4RPi9',
            startDate: '2025-03-07',
            endDate: '2025-03-16',
            title: t('_exhibitions.1.title'),
            description: t('_exhibitions.1.description'),
            title_active: t('_exhibitions.1.title_active'),
            description_active: t('_exhibitions.1.description_active'),
            image: '/images/exhibitions/2025_03_enercare_centre.png',
            name: t('_exhibitions.1.name'),
            booth_no: 'TBD'
        },
        {
            address: t('_exhibitions.0.address'),
            date: t('_exhibitions.0.date'),
            btnLabel: t('_exhibitions.0.btn_label'),
            link: t('_exhibitions.0.link'),
            googleMap: 'https://maps.app.goo.gl/S7Di7XN2xi8NT66c9',
            startDate: '2025-03-16',
            endDate: '2025-03-18',
            title: t('_exhibitions.0.title'),
            description: t('_exhibitions.0.description'),
            title_active: t('_exhibitions.0.title_active'),
            description_active: t('_exhibitions.0.description_active'),
            image: '/images/exhibitions/2025_03_palais_des_congres.png',
            name: t('_exhibitions.0.name'),
            booth_no: '3119'
        }
    ] as IExhibition[];

    return (<>
            {list.map((item: IExhibition, index) => (
                <ExhibitionComponent info={item} key={index}/>
            ))}
    </>)
}

export default ExhibitionScreen;