
export enum CookieNames {
    MAP_TILE_LAYER = 'map-tile-layer',
}

export enum MapTileLayer {
    STANDARD = 'standard',
    GREYSCALE = 'greyscale',
}

export enum DELIVERY_METHOD {
    PICKUP = 'pickup',
    SHIPPING = 'shipping'
}