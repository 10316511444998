import {useInfiniteQuery} from '@tanstack/react-query'
import apiService from "../services/api";
import {IProject, ILastKey} from "../interfaces/IProject";

interface ApiResponse {
    projects: IProject[];
    last_key: ILastKey | null;
}

const fetchProjects = async ({ pageParam }: {pageParam: any}) => {
    const response = await apiService.fetchProjects(3, pageParam)
    return response.data;
};

export const useProjects = () => {
    return useInfiniteQuery<ApiResponse>({
        queryKey: ['projects'],
        queryFn: fetchProjects,
        initialPageParam: null,
        getNextPageParam: (resp) => resp.last_key,
        staleTime: 1000 * 60 * 60 * 24
    });
};