import {useState} from 'react';
import {useTranslation} from "react-i18next";
import {useEffect} from 'react';
import {Routes, Route, useLocation, useNavigate} from "react-router-dom"
import Home from "./pages/Home.page";
import About from "./pages/About.page";
import HowItWorks from "./pages/HowItWorks.page";
import Models from "./pages/Models.page";
import Faq from "./pages/Faq.page";
import Contact from "./pages/Contact.page";
import Model from "./pages/Model.page";
import Technology from "./pages/Technology.page";
import PrivacyPolicy from "./pages/PrivacyPolicy.page";
import TermsOfService from "./pages/TermsOfService.page";
import VCard from "./pages/VCard.page";
import MediaCoverage from "./pages/MediaCoverage.page";
import Gallery from './pages/Gallery.page';
import Shop from './pages/Shop.page';
import Product from './pages/Product.page';
import Blog from './pages/Blog.page';
import Blogs from './pages/Blogs.page';
import Cart from './pages/Cart.page';
import Checkout from './pages/Checkout.page';
import PaymentSuccess from './pages/PaymentSuccess.page'
import NavbarComponent from "./components/Navbar.component";
import FooterComponent from "./components/Footer.component";
import PinCodeComponent from './components/PinCode.component';
import GaTracker from './components/GA.component';
import CookieConsentComponent from './components/CookieConsent.component';
import MetaPixelComponent from './components/MetaPixel.component';
import ls from 'localstorage-slim';
import useCart from './hooks/useCart';
import ErrorComponent from './components/Error.component';
import {getCurrentLang} from './utils/Local';
import RefundPolicy from './pages/RefundPolicy.page';
import ScheduleCallModalComponent from './components/ScheduleCallModal.component';
import UnsubscribeComponent from './components/Unsubscribe.component';
import RedirectComponent from './components/RedirectComponent';
import Project from "./pages/Project";
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'

const queryClient = new QueryClient()

function App() {

    const {i18n} = useTranslation();
    const {cart} = useCart()
    const [authenticated, setAuthenticated] = useState(false);
    const [totalFailedAuth, setTotalFailedAuth] = useState(0);
    const [pin, setPin] = useState('');
    const language = getCurrentLang();
    const navigate = useNavigate()

    useEffect(() => {
        let preferredLang;

        const storedLang = ls.get('userLang');
        const userLang = navigator.language

        if (storedLang) {
            preferredLang = storedLang === 'fr' ? 'fr' : 'en';
        } else if (userLang) {
            preferredLang = userLang.startsWith('fr') ? 'fr' : 'en';
        }

        if (preferredLang) {
            if (window.location.pathname === '/' && preferredLang === 'fr') {
                navigate('/fr')
            } else if (window.location.pathname === '/fr' && preferredLang === 'en') {
                navigate('/')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    async function loadLeaflet() {

        const link = document.createElement('link');
        link.rel = 'stylesheet'
        link.href = `https://unpkg.com/leaflet@1.9.4/dist/leaflet.css`
        link.integrity = 'sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY='
        link.crossOrigin = ""
        document.head.appendChild(link);

        const script = document.createElement('script');
        script.src = `https://unpkg.com/leaflet@1.9.4/dist/leaflet.js`;
        script.integrity = 'sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo='
        script.async = true;
        script.crossOrigin = ""
        document.head.appendChild(script);
    }

    useEffect(() => {
        i18n.changeLanguage(language).then(r => {
            ls.set('userLang', language)
        }).catch(e => {
            console.error(e)
        });
    }, [i18n, language])

    // Scroll to top
    const {pathname} = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // Disable right-click
    useEffect(() => {
        function handleContextMenu(e: any) {
            e.preventDefault();
        }

        const rootElement = document.getElementById('root') as HTMLElement;
        rootElement.addEventListener('contextmenu', handleContextMenu);

        return () => {
            rootElement.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    // Cart
    useEffect(() => {
        ls.set('cart', cart, {ttl: 432000, encrypt: true});
    }, [cart])

    const handlePinComplete = (completedPin: string) => {

        if (completedPin === pin) {
            return;
        }

        setPin(completedPin)

        if (completedPin === process.env.REACT_APP_PIN_CODE) {
            setAuthenticated(true)
        } else {
            setTotalFailedAuth((prev) => prev + 1)
        }
    };

    const innerRoutes = (
        <Route>
            <Route index element={<Home/>}/>
            <Route path={`/`} element={<Home/>}/>
            <Route path={`/fr/`} element={<Home/>}/>

            <Route path={`/models/overview`} element={<Models/>}/>
            <Route path={'/fr/modeles/overview'} element={<Models/>}/>

            <Route path={`/models/:model`} element={<Model/>}/>
            <Route path={'/fr/modeles/:model'} element={<Model/>}/>

            <Route path={`/how-it-works`} element={<HowItWorks/>}/>
            <Route path={`/fr/comment-ca-marche`} element={<HowItWorks/>}/>

            <Route path={`/the-technology`} element={<Technology/>}/>
            <Route path={`/fr/la-technologie`} element={<Technology/>}/>

            <Route path={`/our-story`} element={<About/>}/>
            <Route path={`/fr/notre-histoire`} element={<About/>}/>

            <Route path={`/faq`} element={<Faq/>}/>
            <Route path={`/fr/faq`} element={<Faq/>}/>

            <Route path={`/contact-us`} element={<Contact/>}/>
            <Route path={`/fr/contactez-nous`} element={<Contact/>}/>

            <Route path={`.privacy-policy`} element={<PrivacyPolicy/>}/>
            <Route path={`/fr/politique-de-confidentialite`} element={<PrivacyPolicy/>}/>

            <Route path={`/refund-policy`} element={<RefundPolicy/>}/>
            <Route path={`/fr/politique-de-remboursement`} element={<RefundPolicy/>}/>

            <Route path={`/terms-of-service`} element={<TermsOfService/>}/>
            <Route path={`/fr/conditions-de-service`} element={<TermsOfService/>}/>

            <Route path={`/vcard/:contact`} element={<VCard/>}/>
            <Route path={`/fr/vcard/:contact`} element={<VCard/>}/>

            <Route path={`/media-coverage`} element={<MediaCoverage/>}/>
            <Route path={`/fr/couverture-mediatique`} element={<MediaCoverage/>}/>

            <Route path={'/gallery'} element={<Gallery/>}/>
            <Route path={'/fr/galerie'} element={<Gallery/>}/>

            <Route path={`/shop/:product`} element={<Product/>}/>
            <Route path={`/fr/commandez/:product`} element={<Product/>}/>

            <Route path={`/blog/:blog`} element={<Blog/>}/>
            <Route path={`/fr/blog/:blog`} element={<Blog/>}/>

            <Route path={`/blog`} element={<Blogs/>}/>
            <Route path={`/fr/blog`} element={<Blogs/>}/>

            <Route path={`/shop`} element={<Shop/>}/>
            <Route path={`/fr/commandez`} element={<Shop/>}/>

            <Route path={`/cart`} element={<Cart/>}/>
            <Route path={`/fr/panier`} element={<Cart/>}/>

            <Route path={`/checkout`} element={<Checkout/>}/>
            <Route path={`/fr/caisse`} element={<Checkout/>}/>

            <Route path={`/success`} element={<PaymentSuccess/>}/>
            <Route path={`/fr/succes`} element={<PaymentSuccess/>}/>

            <Route path={`/error`} element={<ErrorComponent/>}/>
            <Route path={`/fr/erreur`} element={<ErrorComponent/>}/>

            <Route path={`/schedule-a-call`} element={<RedirectComponent type='sac'/>}/>
            <Route path={`/fr/planifiez-un-appel`} element={<RedirectComponent type='sac'/>}/>

            <Route path={`/manage-preferences`} element={<UnsubscribeComponent/>}/>
            <Route path={`/fr/gerer-preference`} element={<UnsubscribeComponent/>}/>

            <Route path={`/projects`} element={<Gallery/>}/>
            <Route path={`/fr/projets`} element={<Gallery/>}/>
            <Route path={`/projects/:project`} element={<Project/>}/>
            <Route path={`/fr/projets/:project`} element={<Project/>}/>

        </Route>
    );

    // temp: plain layout | TODO: implement multiple layouts
    const displayDefaultLayout = !(pathname.startsWith('/vcard')) && !(pathname.startsWith('/fr/vcard'))

    GaTracker()
    loadLeaflet().then(r => {
    });

    return (
        <>
            {totalFailedAuth > 4 ?
                <>
                    Error!
                </>
                :
                <>

                    {(process.env.REACT_APP_ENV === 'production' || authenticated || process.env.NODE_ENV === 'development') ?

                        <>
                            <QueryClientProvider client={queryClient}>
                                <MetaPixelComponent/>
                                <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>

                                    {displayDefaultLayout &&
                                        <NavbarComponent/>
                                    }
                                    <div style={{flex: '2 0', position: 'relative'}}>
                                        <Routes>
                                            <Route path="/">
                                                {innerRoutes}
                                            </Route>
                                            <Route path="*" element={<Home/>}/>
                                        </Routes>
                                    </div>

                                    {displayDefaultLayout &&
                                        <>
                                            <FooterComponent/>
                                            <CookieConsentComponent/>
                                            <ScheduleCallModalComponent/>
                                        </>
                                    }
                                </div>
                            </QueryClientProvider>
                        </>

                        :

                        <>
                            <PinCodeComponent onComplete={handlePinComplete}/>
                        </>
                    }
                </>
            }


        </>
    );
}

export default App;