import {Accordion, Col, Container, Row, Image, Button, Modal} from "react-bootstrap";
import {IGalleryImage} from "../interfaces/IGalleryImage";
import i18n from "../i18n";
import {useEffect, useMemo, useState} from "react";
import Loader from "./Loader.component";
import Error from "./Error.component";
import {getCurrentLang} from "../utils/Local";
import {IEvent, IEventImage} from "../interfaces/IEvent";
import Gallery from 'react-image-gallery';
import {useEvents} from "../hooks/useEvents";
import React from "react";

const EventsComponent = () => {

    const {t} = i18n;
    const lang = getCurrentLang()
    const [showGallery, setShowGallery] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    // always show the first 5 events
    const [activeKeys, setActiveKeys] = useState<string[]>(['0', '1', '2', '3', '4', '5']);
    const [galleryImages, setGalleryImages] = useState<IGalleryImage[]>([]);

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
    } = useEvents();

    const events = useMemo(() => {
        return data?.pages.flatMap((page) => page.events) || [];
    }, [data?.pages]);

    const openGallery = (categoryIndex: number, imgIndex: number) => {
        // load images
        const images: IGalleryImage[] = []
        events[categoryIndex].images.map((img, index) => {
            images.push({
                original: img.original,
                thumbnail: img.thumbnail,
                description: `${events[categoryIndex].title[lang]} (${index + 1})`,
                alt: `${events[categoryIndex].title[lang]} (${index + 1})`,
            })
            return null;
        })
        setGalleryImages(images)
        setCurrentIndex(imgIndex);
        setShowGallery(true);
    };

    // Toggle accordion expansion
    const handleToggle = (key: string) => {
        setActiveKeys((prevKeys) =>
            prevKeys.includes(key)
                ? prevKeys.filter((k) => k !== key) // Remove key to collapse
                : [...prevKeys, key] // Add key to expand
        );
    };

    useEffect(() => {
        if (data?.pages.length) {
            const latestPage = data.pages[data.pages.length - 1];
            const latestItemKey = events.length - latestPage.events.length
            setActiveKeys((prevKeys) => [...prevKeys, latestItemKey.toString()]); // Expand the latest item
        }
    }, [data?.pages, events]);

    if (isFetching) {
        return (<Loader/>);
    }

    if (error) {
        return (<Error/>);
    }

    if (!data) {
        return (<>l</>);
    }

    return (
        <>
            <Modal show={showGallery} fullscreen={true} onHide={() => setShowGallery(false)}
                   data-bs-theme="dark" className='modalGallery'>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <Gallery
                        items={galleryImages}
                        startIndex={currentIndex}
                        showThumbnails={false}
                    />
                </Modal.Body>
            </Modal>

            <Accordion activeKey={activeKeys} alwaysOpen>
                {events.map((event: IEvent, index: number) => {
                    return (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                            <Accordion.Header onClick={() => handleToggle(index.toString())}>
                                {event.title[lang]}
                            </Accordion.Header>
                            <Accordion.Body>
                                <Container>
                                    <Row>
                                        {event.images.map((image: IEventImage, imgIndex: number) => {
                                            return (
                                                <Col key={imgIndex} xs={6} md={4}
                                                     className="mb-4 position-relative text-center">
                                                    <Image src={image.thumbnail} role="button"
                                                           className="mw-100"
                                                           onClick={() => openGallery(index, imgIndex)}
                                                           alt={`${event.title[lang]} (${imgIndex + 1})`}
                                                           loading="lazy"/>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Container>
                            </Accordion.Body>
                        </Accordion.Item>
                    )
                })}
            </Accordion>

            {hasNextPage &&
                <Row>
                    <Col xs={12} className='text-center mt-4'>
                        <Button size="sm" variant="outline-success ps-4 pe-4"
                                onClick={() => fetchNextPage().then(() => {
                                })}
                                disabled={isFetchingNextPage}
                                className="btn-outline">
                            {t('load_more')}
                        </Button>
                    </Col>
                </Row>
            }
        </>
    );
};

export default EventsComponent;