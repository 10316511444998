
export function flatPhoneNumber(number: string): string {
    return number.replace(/\D/g, '')
}

export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

export const formatDate = (date: Date, locale: 'en' | 'fr') => {
    return new Date(date).toLocaleDateString(locale, {
        year: "numeric",
        month: "long",
        day: "numeric"
    })
}

export const formatArea = (area: number, locale: 'en' | 'fr') => {
    const unit = locale === 'fr' ? 'pieds carrés' : 'sq ft'
    return area.toFixed(2) + ' ' + unit
}

export const formatWeight = (weight: number) => {
    return weight.toFixed(2) + ' lbs'
}

export const formatDimension = (w: number, l: number, t: number) => {
    return `${w}" x ${l}" x ${t}"`
}

export const formatDistance = (distanceInMeter: number) => {

    const distanceInKm = (distanceInMeter / 1000).toFixed(2)
    return `${distanceInKm} km`
}

export function printValues(obj: any) {
    const values: string[] = [];
    for (var key in obj) {
        if (typeof obj[key] === "object") {
            printValues(obj[key]);
        } else {

            values.push(obj[key])
        }
    }
    return values;
}

export const delay = (ms: number | undefined) => new Promise(res => setTimeout(res, ms));

export const getBaseUrl = () => {
    const protocol = window.location.protocol;
    const host = window.location.hostname;
    return `${protocol}//${host}`;
}

export const youTubeLink = () => {
    return 'https://www.youtube.com/channel/UCOETzq4CMluyhH87DrwMW5g'
}

export const facebookLink = () => {
    return 'https://www.facebook.com/profile.php?id=61552975003426'
}

export const instagramLink = () => {
    return 'https://www.instagram.com/pakville'
}

export const linkedinLink = () => {
    return 'https://www.linkedin.com/company/pakville'
}

export const validateEmail = (email: string) => {
    const result = String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

    return result !== null
};

export const calculateReadTime = (textContent: string): number => {
    if (textContent === undefined || textContent === '') {
        return 0
    }
    const words = textContent.split(/\s+/).filter(Boolean).length;
    const wordsPerMinute = 250; // average reading speed
    return Math.ceil(words / wordsPerMinute);
};