import {Col, Container, Row, Image, Button} from "react-bootstrap";
import i18n from "../i18n";
import React, {useMemo} from "react";
import Loader from "./Loader.component";
import Error from "./Error.component";
import {getCurrentLang, getUrlLangPrefix} from "../utils/Local";
import {IProject} from "../interfaces/IProject";
import {NavLink} from "react-router-dom";
import {displayProjectInfo} from "../utils/Helper";
import {useProjects} from "../hooks/useProjects";

const ProjectsComponent = () => {

    const {t} = i18n;
    const lang = getCurrentLang()
    const langPrefix = getUrlLangPrefix()

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
    } = useProjects();

    const projects = useMemo(() => {
        return data?.pages.flatMap((page) => page.projects) || [];
    }, [data?.pages]);

    const projectLink = (project: IProject) => {
        const title = project.title[lang].replaceAll('?', '').replaceAll(' ', '_');
        return `${langPrefix}/${t('_route.projects')}/${title}?id=${project.id}`
    }

    if (isFetching) {
        return (<Loader/>);
    }

    if (error) {
        return (<Error/>);
    }

    if (!data) {
        return (<>l</>);
    }

    return (
        <>
            {projects.map((project: IProject, index: number) => {
                const imgContainerOrder = index % 2 === 0 ? 'order-1 order-lg-1' : 'order-1 order-lg-2';
                const contentContainerOrder = index % 2 === 0 ? 'order-2 order-lg-2' : 'order-2 order-lg-1';
                return (
                    <Container className={`project-container mt-5 mb-5`} key={index}>
                        <Row>
                            <Col xs={12} lg={6} className={`text-center mb-4  ${imgContainerOrder}`}>
                                <NavLink to={projectLink(project)} key={index}>
                                    <Image src={project.images[0].thumbnail} rounded className="mw-100"/>
                                </NavLink>
                            </Col>
                            <Col xs={12} lg={6} className={contentContainerOrder}>
                                <div className="flexContainer" style={{gap: '8px'}}>
                                    <h2>{project.title[lang]}</h2>
                                    <h3>{project.location}</h3>
                                    <p>{project.description[lang]}</p>
                                    {displayProjectInfo(project, t)}
                                    <NavLink to={projectLink(project)}>
                                        <Button className="btn-outline-primary">{t('View project')}</Button>
                                    </NavLink>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                )
            })}

            {hasNextPage &&
                <Row>
                    <Col xs={12} className='text-center mt-4'>
                        <Button size="sm" variant="outline-success ps-4 pe-4"
                                onClick={() => fetchNextPage().then(() => {
                                })}
                                disabled={isFetchingNextPage}
                                className="btn-outline">
                            {t('load_more')}
                        </Button>
                    </Col>
                </Row>
            }
        </>
    );
};

export default ProjectsComponent;