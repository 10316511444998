import {useInfiniteQuery} from '@tanstack/react-query'
import apiService from "../services/api";
import {IEvent, ILastKey} from "../interfaces/IEvent";

interface ApiResponse {
    events: IEvent[];
    last_key: ILastKey | null;
}

const fetchEvents = async ({ pageParam }: {pageParam: any}) => {
    const response = await apiService.fetchEvents(3, pageParam)
    return response.data;
};

export const useEvents = () => {
    return useInfiniteQuery<ApiResponse>({
        queryKey: ['events'],
        queryFn: fetchEvents,
        initialPageParam: null,
        getNextPageParam: (resp) => resp.last_key,
        staleTime: 1000 * 60 * 60 * 24
    });
};