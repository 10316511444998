import {useState, useEffect} from "react";
import BannerComponent from "../components/Banner.component";
import DocumentComponent from "../components/Document.component";
import ScheduleComponent from "../components/Schedule.component";
import i18n from "../i18n"
import {Container, Tabs, Tab} from "react-bootstrap";
import {useSearchParams} from "react-router-dom";
import 'react-image-gallery/styles/css/image-gallery.css';
import ProjectsComponent from "../components/Projects.component";
import EventsComponent from "../components/Events.component";
import AwardsComponent from "../components/Awards.component";

const tabs = ['projects', 'events', 'awards'];

export default function GalleryPage() {

    const {t} = i18n;
    const [key, setKey] = useState('projects');
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const tab = searchParams.get('tab');
        if (tab && tabs.includes(tab)) {
            setKey(tab);
        }
    }, [searchParams]);

    const handleTabSelect = (tabKey: string | null) => {
        if (tabKey) {
            setKey(tabKey);
            setSearchParams({tab: tabKey}); // Update query params
        }
    };

    return (<>

        <DocumentComponent title={t('gallery')}/>
        <BannerComponent title={t('gallery')} description={t('gallery_description')}/>

        <Container className="galleryContainer">
            <Tabs
                defaultActiveKey="projects"
                id="gallery-tabs"
                className="mb-3"
                activeKey={key}
                onSelect={handleTabSelect}
            >
                <Tab eventKey="projects" title={t('Projects')}>
                    {key === 'projects' &&
                        <ProjectsComponent/>
                    }
                </Tab>
                <Tab eventKey="events" title={t('Events')}>
                    {key === 'events' &&
                        <EventsComponent/>
                    }
                </Tab>
                <Tab eventKey="awards" title={t('Awards')}>
                    {key === 'awards' &&
                        <AwardsComponent/>
                    }
                </Tab>
            </Tabs>
        </Container>

        <ScheduleComponent/>

        <br/>
        <br/>
        <br/>

    </>)
}
