import i18n from "../i18n"
import apiService from '../services/api';
import {useEffect, useState} from 'react';
import {Button, Col, Container, Row} from 'react-bootstrap';
import DocumentComponent from '../components/Document.component';
import Error from '../components/Error.component'
import Loader from '../components/Loader.component'
import 'react-image-gallery/styles/css/image-gallery.css';
import {IBlog, ILastKey} from '../interfaces/IBlog';
import BlogComponent from '../components/Blog.component';
import BannerComponent from '../components/Banner.component';
import ScheduleComponent from "../components/Schedule.component";

export default function Blogs() {

    const {t} = i18n;
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(false)
    const [blogs, setBlogs] = useState<IBlog[]>([]);
    const [lastKey, setLastKey] = useState<ILastKey | undefined>(undefined);

    const fetchBlogs = async () => {
        try {
            const response = await apiService.fetchBlogs(6, lastKey)
            if (response.status === 200 && response.data && response.data.blogs) {
                const blogs = response.data.blogs as IBlog[];
                setBlogs((prevBlogs) => [...prevBlogs, ...blogs]);

                if (response.data.last_key) {
                    const lastEvalKey = response.data.last_key as ILastKey
                    setLastKey(lastEvalKey)
                } else {
                    setLastKey(undefined)
                }
            }

        } catch (err: any) {
            if (err.response && err.response.status && err.response.status === 404) {
                // not found
            } else {
                setError(true)
            }
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchBlogs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (

        <>

            <DocumentComponent title={`${t('blog')}`}/>


            {isLoading && <Loader/>}

            {error && <Error/>}

            {!isLoading && !error && !blogs && 'blogs not found'}

            {blogs && blogs.length > 0 &&

                <>
                    <BannerComponent title={t('blog')} description={t('blog_description')}/>

                    <Container className='mb-4 blogs-container'>
                        <Row>
                            <Col xs={12} lg={{span: 10, offset: 1}} className='mt-2 mb-3'>
                                <Row>
                                    {blogs.filter((blog) => 'title' in blog && 'date' in blog && 'author' in blog).map((blog, index) => {

                                        return (
                                            <Col key={index} xs={{span: 10, offset: 1}} sm={{span: 6, offset: 0}} xl={4}
                                                 className="mb-4">
                                                <BlogComponent blog={blog}/>
                                            </Col>
                                        )

                                    })}

                                    <Col xs={12} className='text-center mt-4'>
                                        {lastKey && !isLoading && (
                                            <Button size="sm" variant="outline-success ps-4 pe-4 " onClick={fetchBlogs}
                                                    className="btn-outline">{t('load_more')}</Button>
                                        )}
                                    </Col>

                                </Row>

                            </Col>
                        </Row>
                    </Container>

                    <ScheduleComponent/>
                </>
            }


        </>
    )
}