import {useSearchParams} from 'react-router-dom';
import ls from 'localstorage-slim';
import i18n from "../i18n"
import apiService from '../services/api';
import {useEffect, useState} from 'react';
import {Col, Container, Row, Image, Modal} from 'react-bootstrap';
import DocumentComponent from '../components/Document.component';
import Error from '../components/Error.component'
import Loader from '../components/Loader.component'
import 'react-image-gallery/styles/css/image-gallery.css';
import {getCurrentLang} from '../utils/Local';
import {IProject, IProjectImage} from '../interfaces/IProject';
import ScheduleComponent from '../components/Schedule.component';
import {displayProjectInfo} from "../utils/Helper";
import {IGalleryImage} from "../interfaces/IGalleryImage";
import Gallery from "react-image-gallery";

export default function Project() {

    const {t} = i18n;
    const lang = getCurrentLang()
    const [searchParams] = useSearchParams()
    const projectId = (searchParams.get('id') || '').toLowerCase()
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(false)
    const [project, setProject] = useState<IProject>()
    const [showGallery, setShowGallery] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const openGallery = (index: number) => {
        setCurrentIndex(index);
        setShowGallery(true);
    };

    const fetchProject = async () => {
        try {

            const cacheProjectKey = `project:${projectId}#`

            const cachedProject = ls.get(cacheProjectKey, {decrypt: true}) as IProject;
            if (cachedProject) {
                setProject(cachedProject)
            } else {
                const response = await apiService.fetchProject(projectId)
                if (response.status === 200 && response.data && response.data.project) {
                    const projectData = response.data.project as IProject;
                    ls.set(cacheProjectKey, projectData, {ttl: 1810, encrypt: true});
                    setProject(projectData)
                }
            }

        } catch (err: any) {

            if (err.response && err.response.status && err.response.status === 404) {
                // not found
            } else {
                setError(true)
            }

        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchProject()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId])


    if (isLoading) {
        return (<Loader/>);
    }

    if (error) {
        return (<Error/>);
    }

    if (!project) {
        return (<>project not found</>);
    }

    const images: IGalleryImage[] = project.images.map((item: IProjectImage, index: number) => {
        return {
            original: item.original,
            thumbnail: item.thumbnail,
            description: `${project?.title[lang]} (${index + 1})`,
            alt: `${project?.title[lang]} (${index + 1})`,
        }
    });

    const renderThumbnails = (start: number = 0, end: number = 0) => {
        const items = project.images.slice(start, end);
        const thumbnailClass = 'mw-100 w-100 h-100 object-fit-cover rounded';
        if (items.length === 3) {
            return (
                <Row>
                    {/* render first image in the batch */}
                    <Col xs={12} lg={8} className={`text-center mb-2 order-lg-2 text-center`}>
                        <Image src={items[0].thumbnail} role='button' className={thumbnailClass} rounded
                               onClick={() => openGallery((start))} alt={`${project.title[lang]} (${start})`}/>
                    </Col>

                    <Col xs={12} lg={4} className='text-center order-lg-1'>
                        <Row>
                            {items.map((item: IProjectImage, index: number) => {

                                // skip the first image
                                if (index === 0) {
                                    return (<span key={index}></span>)
                                }

                                return (
                                    <Col key={index} xs={6} lg={12} className='mb-2 text-center'>
                                        <Image src={item.thumbnail} role='button' className={thumbnailClass} rounded
                                               onClick={() => openGallery((start + index))}
                                               alt={`${project.title[lang]} (${index + start})`}/>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Col>
                </Row>
            )
        }

        const itemLength = items.length;
        return (
            <Row>
                {items.map((item: IProjectImage, index: number) => {
                    let gridSize = 6;
                    if (itemLength === 2 && end === project.images.length) {
                        if (index === 0) {
                            gridSize = 4;
                        } else {
                            gridSize = 8;
                        }

                    } else if (itemLength === 1) {
                        gridSize = 12;
                    }
                    return (
                        <Col key={index} xs={gridSize} className='mt-3 mb-3 text-center rounded'>
                            <Image src={item.thumbnail} role='button' className={thumbnailClass} rounded
                                   onClick={() => openGallery((start + index))}
                                   alt={`${project.title[lang]} (${index + start})`}/>
                        </Col>
                    )
                })}
            </Row>
        )
    }

    const cleanTitle = project.title[lang].replaceAll('?', '').replaceAll(' ', '_');


    return (
        <>
            <DocumentComponent title={`${t('project')} > ${project.title[lang]}`} og={{
                description: cleanTitle,
                image: {
                    src: project.images[0].original,
                    type: 'image/jpg',
                    width: 800,
                    height: 450
                }
            }}/>

            <Modal show={showGallery} fullscreen={true} onHide={() => setShowGallery(false)} data-bs-theme="dark"
                   className='modalGallery'>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <Gallery
                        items={images}
                        startIndex={currentIndex}
                        showThumbnails={false}
                    />
                </Modal.Body>
            </Modal>


            <Container className='mb-4 project-container'>
                <Row>
                    <Col xs={12} lg={{span: 10, offset: 1}} className='mt-5 mb-3'>
                        <Row>
                            <Col xs={12} className='mb-4'>
                                <h1>{project.title[lang]}</h1>
                                <p>{project.description[lang]}</p>
                            </Col>

                            <Col xs={12} className='mb-4'>
                                <Image src={project.images[0].original} rounded alt={project.title[lang]}
                                       className="mw-100"/>
                            </Col>
                        </Row>
                        <Row className='mb-4'>
                            <Col xs={12}>
                                <div className="project-info-container">
                                    {displayProjectInfo(project, t)}
                                </div>
                            </Col>
                        </Row>

                        {renderThumbnails(1, 4)}
                        {renderThumbnails(4, 5)}
                        {renderThumbnails(5, 7)}
                        {renderThumbnails(7, 8)}
                        {renderThumbnails(8, 10)}
                    </Col>
                </Row>
            </Container>
            <ScheduleComponent/>
        </>
    )
}